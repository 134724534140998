import React, { useCallback } from 'react'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as CrossSVG } from './img/cross.svg'

const CrossIcon = styled(CrossSVG)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
`

const rotate = keyframes`
  from {
    transform: scale(0.5) rotate(180deg);
  }
  to {
    transform: scale(1) rotate(0);
  }
`

export const ClearButton = styled(({ className, onClick }) => {
  const mouseDownHandler = useCallback((event) => event.preventDefault(), [])
  return (
    <button className={className} onMouseDown={mouseDownHandler} tabIndex="-1">
      <CrossIcon onClick={onClick} />
    </button>
  )
})`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  text-align: center;
  height: 18px;
  width: 18px;
  animation: ${rotate} 0.33s linear 1 alternate;
`

import { useCallback, useMemo, useState } from 'react'

export const useReplacedField = (field, initialValue) => {
  const [value, setValue] = useState(initialValue)

  const onChange = useCallback(
    (event) =>
      setValue(
        typeof event === 'object' && event.target ? event.target.value : event
      ),
    []
  )

  return useMemo(
    () => ({
      ...field,
      onChange: field.onChange ?? onChange,
      value: field.value ?? value
    }),
    [field, value, onChange]
  )
}

export const useReplacedCheckboxField = (field) => {
  const [checked, setChecked] = useState()
  const [value, setValue] = useState()

  const onChange = useCallback(
    (event) => {
      setValue(typeof event === 'object' ? event.target.value : event)
      setChecked(!checked)
    },
    [checked]
  )

  return useMemo(
    () => ({
      ...field,
      checked: field.checked ?? checked,
      onChange: field.onChange ?? onChange,
      value: field.value ?? value
    }),
    [field, value, checked, onChange]
  )
}

import { ClearButton as DefaultClearButton } from './ClearButton'
import { EyeButton } from './EyeButton'
import { Montserrat } from '../../constants'
import { color } from '../../styles/vars/colors'
import { css } from 'styled-components'

const sizeCSS = ({ size }) =>
  ({
    medium: css`
      & input {
        height: 40px;
      }
    `,
    large: css`
      & input {
        height: 48px;
        padding: 14px;
        padding-right: 36px;
      }

      & ${DefaultClearButton}, ${EyeButton} {
        right: 14px;
      }
    `
  }[size])

const searchMode = ({ searchMode }) =>
  searchMode &&
  css`
    & input {
      padding-left: ${({ size }) => (size === 'large' ? '40px' : '36px')};
    }
  `

const errorCSS = ({ error }) =>
  error &&
  css`
    &:hover,
    &:active {
      &::before {
        z-index: -1 !important;
      }
    }

    & input {
      border-color: ${color.formItem.border.error};

      &:hover,
      &:active {
        border-color: ${color.formItem.border.error};
      }

      &:hover {
        background: ${color.formItem.background.errorHover};
      }

      &:focus {
        border-color: ${color.formItem.border.error};
      }
    }
  `

const boxShadowMaskCSS = ({ disabled }) =>
  !disabled &&
  css`
    &:not(:focus-within):not(:-webkit-autofill):not(
        :-webkit-autofill:hover
      ):not(:-webkit-autofill:focus):not(:active) {
      &::before {
        content: '';
        cursor: pointer;
        pointer-events: none;
        position: absolute;
        right: ${({ size }) => (size === 'large' ? '32px' : '24px')};
        z-index: 1;
        width: 2ch;
        display: block;
        top: 0;
        bottom: 0;
        margin: auto;
        height: calc(100% - 4px);
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 0.6699054621848739) 100%
        );
      }
    }
  `

export const inputCSS = css`
  position: relative;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;

  & ${DefaultClearButton}, ${EyeButton} {
    position: absolute;
    z-index: 2;
    right: 10px;
    display: block;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }

  & input {
    font-family: ${Montserrat};
    width: 100%;
    height: 40px;
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${color.formItem.text.default};
    border-radius: 4px;
    padding-right: 32px;
    outline: none;
    border: 1px solid ${color.formItem.border.default};
    height: 40px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      caret-color: ${color.formItem.text.default} !important;
      background-color: ${color.background} !important;
      color: ${color.formItem.text.default} !important;
      -webkit-text-fill-color: ${color.formItem.text.default};
      box-shadow: 0 0 0 48px inset ${color.background} !important;
      -webkit-box-shadow: 0 0 0 48px inset ${color.background} !important;
    }

    &::placeholder {
      color: ${color.formItem.placeholder};
    }

    &:hover {
      border-color: ${color.formItem.border.hover};
    }

    &:focus {
      border-color: ${color.formItem.border.focus};
    }

    &::placeholder {
      color: ${color.formItem.placeholder};
    }

    &:disabled {
      background: ${color.formItem.background.disabled};
      border-color: ${color.formItem.background.disabled};
      color: ${color.formItem.text.valueDisabled};
    }

    ${({ success }) =>
      success &&
      css`
        border-color: #03b2a5 !important;
      `}
  }

  ${boxShadowMaskCSS}
  ${errorCSS}
  ${sizeCSS}
  ${searchMode}
`

import React, { useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'
import { ClearButton as DefaultClearButton } from './ClearButton'
import { color } from '../../styles/vars/colors'
import { omit } from 'rambda'
import { size } from '../../constants'

const sizeCSS = ({ size }) =>
  ({
    medium: css`
      & input {
        height: 40px;
      }
    `,
    large: css`
      & input {
        height: 48px;
        padding: 14px;
        padding-right: 32px;
      }
      & ${DefaultClearButton} {
        right: 14px;
      }
    `
  }[size])

const errorCSS = ({ error }) =>
  error &&
  css`
    &:hover,
    &:active {
      &::before {
        z-index: -1;
      }
    }

    & input {
      border-color: ${color.formItem.border.error};

      &:hover,
      &:active {
        border-color: ${color.formItem.border.error};
      }

      &:hover {
        background: ${color.formItem.background.errorHover};
      }

      &:focus {
        border-color: ${color.formItem.border.error};
      }
    }
  `

const resizeCSS = ({ noresize }) =>
  !noresize &&
  css`
    @media (max-width: ${size.sm}) {
      max-height: 48px;

      & input {
        padding: 14px;
        height: 48px;
      }

      & ${DefaultClearButton} {
        right: 14px;
      }
    }
  `

export const Input = styled(
  ({ className, clearCondition = true, ...field }) => {
    const ref = useRef()
    const onClear = useCallback(() => {
      ref.current.focus()
      field.onChange('')
    }, [field.onChange])

    return (
      <div className={className}>
        <input ref={ref} {...omit(['error', 'success'], field)} />
        {field.value && clearCondition && (
          <DefaultClearButton onClick={onClear} />
        )}
      </div>
    )
  }
)`
  position: relative;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;

  & ${DefaultClearButton} {
    position: absolute;
    z-index: 2;
    right: 10px;
    display: block;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }

  & input {
    width: 100%;
    height: 40px;
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${color.formItem.text.default};
    border-radius: 4px;
    padding-right: 32px !important;
    outline: none;
    border: 1px solid ${color.formItem.border.default};
    color: ${color.formItem.text.default};
    height: 40px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 48px inset #fff !important;
      -webkit-box-shadow: 0 0 0 48px inset #fff !important;
    }

    &::placeholder {
      color: ${color.formItem.placeholder};
    }

    &:hover {
      border-color: ${color.formItem.border.hover};
    }

    &:focus {
      border-color: ${color.formItem.border.focus};
    }

    &::placeholder {
      color: ${color.formItem.placeholder};
    }

    &:disabled {
      background: ${color.formItem.background.disabled};
      border-color: ${color.formItem.background.disabled};
    }

    ${({ success }) =>
      success &&
      css`
        border-color: #03b2a5 !important;
      `}
  }

  &:not(:focus-within) {
    &::before {
      content: '';
      cursor: pointer;
      pointer-events: none;
      position: absolute;
      right: 24px;
      z-index: 1;
      width: 2ch;
      display: block;
      top: 0;
      bottom: 0;
      margin: auto;
      height: calc(100% - 4px);
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0.6699054621848739) 100%
      );
    }
  }

  ${errorCSS}
  ${resizeCSS}
  ${sizeCSS}
`

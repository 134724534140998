import React, { useCallback, useEffect, useMemo } from 'react'
import Select, { Option } from 'rc-select'
import styled from 'styled-components'
import { ReactComponent as Arrow } from '../img/arrow.svg'
import { Text } from '../../Text'
import { color } from '../../../styles/vars/colors'
import { dissoc } from 'ramda'
import { dropDownCSS, gradientTextCSS, selectStyles } from './styles'

export const StyledSelect = styled(Select)`
  ${selectStyles}
`

const OptionDescription = styled(Text.Average)`
  margin-left: 8px;
  color: ${color.formItem.select.option.description};
`

const SelectItem = styled(({ className, text, description }) => (
  <div className={className}>
    <Text.Average>{typeof text === 'function' ? text() : text}</Text.Average>
    {description && (
      <OptionDescription>
        {typeof description === 'function' ? description() : description}
      </OptionDescription>
    )}
  </div>
))`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  & > ${Text.Average} {
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    width: 100%;
    ${gradientTextCSS}
  }
`

const useOptions = (options, disabled) =>
  options.map(({ value, text, description }) => (
    <Option key={text} value={value}>
      <SelectItem description={description} disabled={disabled} text={text} />
    </Option>
  ))

const DropdownPortal = styled(({ className, id }) => (
  <div className={className} id={id} />
))`
  ${dropDownCSS}
`

export const InputSelect = styled(
  ({ className, options, id, virtual = true, ...field }) => {
    const Options = useOptions(options, field.disabled)

    useEffect(() => {
      if (id && document && field?.name) {
        //needed for decorator else not working
        document.getElementById(id).setAttribute('name', field.name)
      }
    }, [id, field.name])

    const selectField = useMemo(
      () => dissoc(field.value ? '' : 'value', field),
      [field]
    )

    return (
      <>
        <StyledSelect
          className={className}
          getPopupContainer={useCallback(
            () => document.getElementById(`dropdown-${field.name}`),
            [field.name]
          )}
          suffixIcon={Arrow}
          {...selectField}
          disabled={selectField.disabled}
          id={id}
          placeholder={selectField.placeholder}
          virtual={virtual}
        >
          {Options}
        </StyledSelect>
        <DropdownPortal id={`dropdown-${field.name}`} />
      </>
    )
  }
)``

import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { AnimateSharedLayout, motion } from 'framer-motion'
import { Label } from './FormItem'
import { WizardCheckbox } from '../atoms/Checkbox'
import { color } from '../styles/vars/colors'
import { size } from '../constants'

export const useCheckboxTabsToggler = (defaultValue) => {
  const [checked, setChecked] = useState(defaultValue)

  const handleCheck = useCallback((event) => {
    if (typeof event === 'object') return setChecked(event.target.value)

    return setChecked(event)
  }, [])

  return [checked, handleCheck]
}

const Mask = styled(({ className }) => (
  <motion.div
    className={className}
    initial={false}
    layoutId="outline"
    transition={{
      duration: 0.33,
      ease: 'linear'
    }}
  />
))`
  border-radius: 24px;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  box-shadow: 0px 0px 0px 2px rgba(22, 200, 187, 1);
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  z-index: 3;
`

export const CheckboxMask = styled(({ className, children, visible }) => (
  <motion.div className={className}>
    {{
      ...children,
      props: { ...children.props, Mask: visible ? <Mask /> : null }
    }}
  </motion.div>
))`
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const AnimateToggleMask = styled(({ className, children }) => (
  <AnimateSharedLayout>
    <div className={className}>{children}</div>
  </AnimateSharedLayout>
))`
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${color.background};
  border-radius: 24px;
  border: 1px solid ${color.checkbox.border};
  box-sizing: border-box;

  @media (max-width: ${size.sm}) {
    ${WizardCheckbox} {
      flex-grow: 1;
    }
  }

  & ${WizardCheckbox} input + label {
    z-index: 0;
  }

  & ${WizardCheckbox} input:checked + label {
    z-index: 1;
  }
`

export const CheckboxTabsToggler = styled(
  ({ className, handleCheck, checked, checkboxes }) => (
    <AnimateToggleMask className={className}>
      {checkboxes.map((checkbox) => (
        <CheckboxMask key={checkbox.value} visible={checked === checkbox.value}>
          <WizardCheckbox
            key={checkbox.id}
            {...checkbox}
            noBordered
            checked={checked === checkbox.value}
            onClick={handleCheck}
          />
        </CheckboxMask>
      ))}
    </AnimateToggleMask>
  )
)``

export const CheckboxTabsTogglerWithLabel = styled(
  ({ className, label, ...props }) => (
    <div className={className}>
      <Label>{label}</Label>
      <CheckboxTabsToggler {...props} />
    </div>
  )
)`
  & > label {
    margin-bottom: 8px;
  }
`

import 'rc-select/assets/index.less'
import 'rc-slider/assets/index.css'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Range as RcRange } from 'rc-slider'
import { Text } from './Text'

const InfoBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;

  & ${Text.Medium} {
    font-size: 14px;
    line-height: 20px;
  }
`

const RangeStyled = styled.div`
  .rc-slider {
    height: 16px;
    display: flex;
    align-items: center;
    border-radius: 18px;
    width: calc(100% - 16px);
    transform: translateX(8px);
  }

  .rc-slider-rail {
    height: 2px;
    background: #a2a7b1;
  }

  .rc-slider-track {
    background: #c1ebe9;
    height: 2px;
  }

  .rc-slider-handle {
    height: 16px;
    width: 16px;
    background-color: #03b2a5;
    border-radius: 50%;
    border: unset;
    margin-top: 0;
  }

  .rc-slider-handle:hover {
    background-color: #14b4a8 !important;
  }

  .rc-slider-handle-dragging {
    background-color: #14b4a8;
    box-shadow: unset !important;
    border-color: unset !important;
  }

  & input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
`

export const Range = styled(({ rangeValues, setRangeValues, className }) => {
  const [renderingValues, setRenderingValues] = useState(rangeValues)

  useEffect(() => {
    const [minAge, maxAge] = rangeValues
    if (minAge === 24 && maxAge === 65) {
      setRenderingValues([24, 65])
    }
  }, [rangeValues])

  const handleChange = useCallback((values) => {
    setRangeValues(values)
  }, [])

  return (
    <RangeStyled className={className}>
      <RcRange
        key={rangeValues}
        defaultValue={rangeValues}
        max={65}
        min={24}
        onAfterChange={handleChange}
        onChange={setRenderingValues}
      />
      <InfoBlock>
        <Text.Medium>Возраст</Text.Medium>
        <Text.Medium>
          {renderingValues[0]}&nbsp;-&nbsp;{renderingValues[1]}
        </Text.Medium>
      </InfoBlock>
    </RangeStyled>
  )
})``

const getRenderingRange = (renderingValues) =>
  Array.isArray(renderingValues) ? renderingValues.join(' - ') : null

export const RangeAsField = styled(
  ({ rangeValues, className, label, ...field }) => {
    const [renderingValues, setRenderingValues] = useState(field.value)
    const [minAge, maxAge] = rangeValues

    const handleChange = useCallback((values) => {
      field.onChange(values)
    }, [])

    return (
      <RangeStyled className={className}>
        {label && <Text.Medium>{label}</Text.Medium>}
        <InfoBlock>
          <Text.Medium>{getRenderingRange(renderingValues)}</Text.Medium>
        </InfoBlock>
        <RcRange
          key={field?.value}
          defaultValue={field?.value}
          max={maxAge}
          min={minAge}
          onAfterChange={handleChange}
          onChange={setRenderingValues}
        />
        {field?.name && <input {...field} id={field?.name} />}
      </RangeStyled>
    )
  }
)`
  width: 100%;

  ${InfoBlock} {
    justify-content: flex-end;
  }

  ${Text.Medium} {
    font-weight: 600;
    text-align: left;
  }
`

import React, { useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'
import { ClearButton } from './ClearButton'
import { color } from '../../styles/vars/colors'
import { size } from '../../constants'

const errorCSS = ({ error }) =>
  error &&
  css`
    border-color: ${color.formItem.border.error};

    &:hover,
    &:active {
      border-color: ${color.formItem.border.error};
    }

    &:hover {
      background: ${color.formItem.background.errorHover};
    }

    &:focus {
      border-color: ${color.formItem.border.error};
    }
  `

const resizeCSS = ({ noresize }) =>
  !noresize &&
  css`
    @media (max-width: ${size.sm}) {
      & textarea {
        padding: 14px;
      }

      & ${ClearButton} {
        right: 14px;
        top: 14px;
      }
    }
  `

const sizeCSS = ({ size }) =>
  ({
    medium: css`
      & textarea {
        padding: 10px;
      }

      & ${ClearButton} {
        right: 10px;
        top: 10px;
      }
    `,
    large: css`
      & textarea {
        padding: 14px;
      }

      & ${ClearButton} {
        right: 14px;
        top: 14px;
      }
    `
  }[size])

export const TextArea = styled(({ className, ...field }) => {
  const ref = useRef()
  const onClear = useCallback(() => {
    ref.current.focus()
    field.onChange('')
  }, [field.onChange])

  return (
    <div className={className}>
      <textarea ref={ref} {...field} />
      {field.value && <ClearButton onClick={onClear} />}
    </div>
  )
})`
  position: relative;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  height: 174px;
  width: 100%;

  & ${ClearButton} {
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  & textarea {
    height: inherit;
    width: 100%;
    resize: none;
    padding: 10px;
    padding-right: 36px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${color.formItem.text.default};
    border-radius: 4px;
    outline: none;
    border: 1px solid ${color.formItem.border.default};

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 48px inset #fff !important;
      -webkit-box-shadow: 0 0 0 48px inset #fff !important;
    }

    &:hover {
      border-color: ${color.formItem.border.hover};
    }

    &:focus {
      border-color: ${color.formItem.border.focus};
    }

    &::placeholder {
      color: ${color.formItem.placeholder};
    }

    &:disabled {
      background: ${color.formItem.background.disabled};
      border-color: ${color.formItem.background.disabled};
    }

    &::-webkit-scrollbar {
      background: transparent;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${color.formItem.scrollbar};
      border-radius: 3px;
    }

    ${errorCSS}
  }

  ${resizeCSS}
  ${sizeCSS}
`

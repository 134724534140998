import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { EyeButton } from './EyeButton'
import { inputCSS } from './styles'
import { omit } from 'rambda'
import { useReplacedField } from './useReplacedField'
// import { useReplacedField } from '../useReplacedField'

const passwordType = 'password'

const getRequestIdleCallbackPolyfills = () => {
  if (typeof window.requestIdleCallback === 'undefined') {
    const polyfillRIC = (cb) => setTimeout(cb, 0)
    window.requestIdleCallback = window.requestAnimationFrame || polyfillRIC
  }
}

const useSaveCarretPosition = () => {
  const [carretPosition, setCarretPosition] = useState({})
  const ref = useRef()

  useEffect(() => {
    getRequestIdleCallbackPolyfills()
    ref.current &&
      window.requestIdleCallback(() => {
        ref.current.selectionEnd = carretPosition.selectionEnd
        ref.current.selectionStart = carretPosition.selectionStart
      })
  }, [carretPosition])

  return { ref, setCarretPosition }
}

export const InputPassword = styled(({ className, ...field }) => {
  const [inputType, setInputType] = useState(passwordType)
  const { ref, setCarretPosition } = useSaveCarretPosition()
  const replacedField = useReplacedField(field)
  const onClickIcon = useCallback(() => {
    const { selectionEnd, selectionStart } = ref.current
    ref.current.focus()
    inputType === passwordType ? setInputType() : setInputType(passwordType)
    setCarretPosition({ selectionEnd, selectionStart })
  }, [inputType])

  const inputProps = useMemo(
    () =>
      omit(['error', 'success', inputType ? '' : 'type'], {
        ...replacedField,
        type: inputType
      }),
    [replacedField, inputType]
  )

  return (
    <div className={className}>
      <input {...inputProps} ref={ref} />
      <EyeButton
        disabled={field.disabled}
        onClick={onClickIcon}
        visible={inputType !== passwordType}
      />
    </div>
  )
})`
  ${inputCSS}
`
